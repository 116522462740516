import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Media from 'react-bootstrap/Media';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Title = styled.h1`
  margin-bottom: 20px;
`;

const CompanyColumn = styled(Col)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 10px;
`;

const CompanyContainer = styled.div``;

const CompanyName = styled.h3``;

const CompanyDescription = styled.p`
  margin-bottom: 0;
`;

const CompanyLogoImage = styled(Image)`
  border: 1px solid #d3d3d3;
`;

const Companies = ({ data, location }) => {
  const companies = data.companies.edges;
  return (
    <Layout location={location}>
      <SEO title="求人掲載企業" />
      <h1 className="display-1">foriio Jobsにて求人情報掲載中の企業</h1>
      <Row>
        {companies.map(comp => {
          return (
            <CompanyColumn md={6} className="mb-2">
              <CompanyContainer>
                <Media>
                  <CompanyLogoImage
                    className="mr-3"
                    roundedCircle
                    src={comp.node.frontmatter.logo?.childImageSharp.fluid.src}
                    alt="logo"
                  />
                  <Media.Body>
                    <CompanyName> {comp.node.frontmatter.name}</CompanyName>
                    <CompanyDescription>
                      {comp.node.frontmatter.catchCopy}
                    </CompanyDescription>
                    {comp.node.frontmatter.jobs.length > 0 && (
                      <Link
                        to={comp.node.fields.slug}
                        className="stretched-link"
                      >
                        {comp.node.frontmatter.jobs.length} open jobs
                      </Link>
                    )}
                  </Media.Body>
                </Media>
              </CompanyContainer>
            </CompanyColumn>
          );
        })}
      </Row>
    </Layout>
  );
};

export default Companies;

export const pageQuery = graphql`
  query Companies($currentDate: Date!) {
    companies: allMarkdownRemark(
      sort: { fields: [frontmatter___createdDate], order: DESC }
      filter: {
        fileAbsolutePath: { glob: "**/content/companies/*/**.md" }
        frontmatter: {
          jobs: {
            elemMatch: { frontmatter: { expiryDate: { gte: $currentDate } } }
          }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            jobId
            name
            catchCopy
            website
            location
            logo {
              childImageSharp {
                fluid(maxWidth: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            jobs {
              fields {
                slug
              }
            }
          }
        }
      }
    }
  }
`;
